<template>
  <div>
    <CRow class="justify-content-center">
      <CCol md="12" lg="8">
        <CRow class="justify-content-start m-4">
          <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="$router.go(-1)">{{ $t('cloud.title.go_back') }}</el-button>
        </CRow>
        <CRow class="justify-content-center">
          <CCard class="w-75">
            <CCardHeader>
              <div class="mx-1 custom-setting-title-label">{{ $t('cloud.title.setting') }}</div>
              <div>
                <CButton v-if="!basic_setting_edit" class="mx-1 custom-setting-config-btn" @click="onEdit" color="info">{{ $t('button.edit') }}</CButton>
                <CButton v-if="basic_setting_edit" class="mx-1 custom-setting-config-btn" @click="onSubmit" color="info">{{ $t('button.apply') }}</CButton>
                <CButton v-if="basic_setting_edit" class="mx-1 custom-setting-config-btn" @click="onCancel" color="secondary">{{ $t('button.cancel') }}</CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow class="mx-2 mb-3">
                <CCol class="custom-setting-label" sm="3" >
                  Language / 언어 / 語
                </CCol>
                <CCol sm="9">
                  <el-select class="w-75 my-1" size="small"  v-model="selected_locale" :disabled="!basic_setting_edit">
                    <el-option
                      v-for="(language, index) in this.LANG_OPTS"
                      :key="`language-${index}`"
                      :label="language.label"
                      :value="language.locale">
                    </el-option>
                  </el-select>
                </CCol>
              </CRow>
              <CRow class="mx-2 mb-3">
                <CCol class="custom-setting-label" sm="3" >
                  {{ $t('cloud.title.setting_timezone') }}
                </CCol>
                <CCol sm="9">
                  <el-select class="w-75 my-1" size="small" v-model="selected_timezone" filterable :disabled="!basic_setting_edit">
                    <el-option
                      v-for="(tz,index) in tz_list"
                      :key="`timezones-${index}`"
                      :label="tz"
                      :value="tz">
                    </el-option>
                  </el-select>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import axios from 'axios';
import qs from 'qs';
import { mapState } from 'vuex'

export default {
  name: 'Settings',

  computed: {
    ...mapState([
      'capability'
    ])
  },
  data(){
    return {
      basic_setting_edit : false,
      selected_locale : 'en',
      tz_list : [],
      searchText: null,
      selected_timezone : null,
      LANG_OPTS: [
        {locale: 'en', label: "English"},
        {locale: 'ko', label: "한국어"}
      ]
    }
  },
  mounted(){
    this.selected_locale = this.capability.user_profile.locale;
    this.selected_timezone = this.capability.user_profile.timezone;
    this.getTimezones();
  },
  methods : {
    getTimezones() {
      let query_string = '';
      const params = {
        search: this.searchText
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/timezones/${query_string}`)
        .then(result => {
          this.tz_list = result.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onEdit(){
      this.basic_setting_edit = !this.basic_setting_edit;
    },
    onSubmit(){
      axios.put('/api/users/setting/', {
        locale: this.selected_locale,
        timezone: this.selected_timezone
      })
        .then(() => {
          this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done'),
              offset: 30
            });
          localStorage.lang = this.selected_locale;
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: this.$t('cloud.title.error'),
            message: `${this.$t('cloud.title.request_fail')}: ${error.response.data.error}`,
            offset: 30
          });
        })
        .finally(() => {
          this.basic_setting_edit = !this.basic_setting_edit;
        })
    },
    onCancel(){
      this.basic_setting_edit = !this.basic_setting_edit;
    }
  }
}
</script>
